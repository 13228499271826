import React from 'react';
import { withRouter } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EventIcon from './imgs/icona-eventi.svg';
import LabIcon from './imgs/icona-laboratori.svg';
import ProfileIcon from './imgs/icona-profilo.svg';
import EventIconOn from './imgs/icona-eventi-on.svg';
import LabIconOn from './imgs/icona-laboratori-on.svg';
import ProfileIconOn from './imgs/icona-profilo-on.svg';

export default withRouter(function(props) {

	const onClick = (event, newValue) => {
		switch (newValue) {
			case 0:
				props.history.push('/chi-siamo');
				break;
			case 1:
				props.history.push('/eventi');
				break;
			case 2:
				props.history.push('/laboratori');
				break;
		}
		props.setNav(newValue)
	}

	return <BottomNavigation value={props.nav} onChange={onClick} showLabels className="bottomnav">
			<BottomNavigationAction label="Chi siamo" icon={<img src={props.nav === 0 ? LabIconOn : LabIcon} className="bottomicon" alt="" />} />
			<BottomNavigationAction label="Eventi" icon={<img src={props.nav === 1 ? EventIconOn : EventIcon} className="bottomicon" alt="" />} />
			<BottomNavigationAction label="Laboratori" icon={<img src={props.nav === 2 ? ProfileIconOn : ProfileIcon} className="bottomicon" alt="" />} />
		</BottomNavigation>
});
export const parseObject = (obj, aKey = '') => {
	const oData = [];
	let key;
	for (key in obj) {
		const value = obj[key];
		key = encodeURIComponent(key);
		if (typeof value === 'object') {
			const tmp = parseObject( value, aKey ? aKey + "[" + key + "]" : key);
			oData[ oData.length ] = tmp
		} else {
			const sVal = encodeURIComponent(typeof value === "function" ? value() : (typeof value === 'boolean' ? (value ? 1 : 0) : value));
			oData[oData.length] = aKey ? aKey + "[" + key + "]=" + (sVal === null ? "" : sVal) : key + "=" + (sVal === null ? "" : sVal);
		}
	}
	if (oData.length === 0 && aKey) {
		return aKey + "=";
	} else {
		return oData.join("&");
	}
}

export const ajaxReq = (url, data, callback, callbackError, setLoader, openSnackbar) => {
	if (setLoader) {
		setLoader(true);
	}
	ajax({
		url: url,
		method: 'post',
		dataType: 'json',
		data: data,
	}).then(response => {
		if (setLoader) {
			setLoader(false)
		}
		if (response.result === 'ok') {
			if (response.msg && openSnackbar) {
				openSnackbar(response.msg, 'success')
			}
			if (callback) {
				callback(response);
			}
		} else {
			if (openSnackbar) {
				if (response.msg) {
					openSnackbar(response.msg, 'error')
				} else {
					openSnackbar('Ops! c\'è stato un problema, per favore riprova tra poco', 'error')
				}
			}
			if (callbackError) {
				callbackError(response);
			}
		}
	}).catch(error => {
		if (setLoader) {
			setLoader(false)
		}
		console.log('Error:', error)
		if (openSnackbar) {
			openSnackbar('Ops! c\'è stato un problema, per favore riprova tra poco', 'error')
		}
	})
}


export const ajax = (opt) => {
	return new Promise((resolve, reject) => {
		const headers = opt.headers ? opt.headers : {};
		if (headers['Content-Type'] === undefined) {
			headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
			//headers['Content-Type'] = 'application/json';
		}
		if (opt.dataType) {
			headers['Accept'] = {
				text: 'text/plain',
				html: 'text/html',
				xml: 'application/xml, text/xml',
				json: 'application/json, text/javascript'
			}[opt.dataType];
		}
		//headers['X-CSRF-TOKEN'] = document.getElementById('csrf_token').innerHTML;
		const props = {
			method: opt.method ? opt.method.toUpperCase() : 'GET',
			headers: headers,
		}
		if (props.method === 'POST') {
			props['body'] = opt.data ? parseObject(opt.data) : null;
		}
		//props['mode'] = 'no-cors';
		fetch('https://www.fbnai.hmd.it/api/' + opt.url, props).then(response =>  {
			if (opt.dataType === 'json') {
				return response.json();
			} else {
				return response;
			}
		}).then(response =>  {
			resolve(response);
		}).catch(error => {
			reject(error)
		});
	});
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ajaxReq } from './Helper';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Loader from './imgs/progress.svg';


const Event = (props) => {
	return <Button className="btn-lab" onClick={props.onClick}>
		<div className="lab">
			<Typography className="tx8">{props.data.title}</Typography>
			<div className="lab-info">
				{props.data.image && <img src={props.data.image} className="lab-img" alt="" />}
				<div className="lab-side">
					<div className="lab-date">
						<Typography className="lab-date-d">{props.data.date}</Typography>
					</div>
					<Typography className="lab-category">{props.data.place}</Typography>
				</div>
			</div>
		</div>
	</Button>
}


class Events extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: [],
			showLoader: true,
		}

		this.onClick = this.onClick.bind(this);

	}

	componentDidMount() {
		this.props.setNav(1);
		ajaxReq('eventi', null, (resp) => {
			this.setState({events:resp.data.events, showLoader: false})
		}, null, null, null);
	}

	onClick(id) {
		this.props.history.push('/evento/' + id);
	}

	render() {
		return (
			<div className="app-content">
				{this.state.showLoader && <div className="loader-content"><img src={Loader} alt="" /></div>}
				{this.state.events.map(itm => <Event key={itm.permalink} data={itm} onClick={() => this.onClick(itm.permalink)} />)}
			</div>
		);
	}

}


export default withRouter(Events);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Loader from './imgs/progress.svg';
import { ajaxReq } from './Helper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';


class Event extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			showLoader: true,
		}

		this.back = this.back.bind(this);
	}

	back() {
		this.props.history.push('/eventi');
	}

	componentDidMount() {
		this.props.setNav(1);
		ajaxReq('eventi/' + this.props.location.pathname.split('/')[2], null, (resp) => {
			this.setState({data:resp.data.event, showLoader: false})
		}, null, null, null);
	}

	render() {
		return (
			<>
				<div className="app-content">
					<AppBar className="app-bar" position="sticky">
						<Toolbar>
				            <div className="app-bar-back" onClick={this.back}><KeyboardBackspace /></div>
							<Typography variant="h6" className="app-bar-title2">{this.state.data && this.state.data.title}</Typography>
						</Toolbar>
					</AppBar>
					{this.state.showLoader && <div className="loader-content"><img src={Loader} alt="" /></div>}
					<>
						{this.state.data && this.state.data.image && <img src={this.state.data.image} className="img3" alt="" />}
						{this.state.data && <div className="main-container">
							
					        <div className="MuiTypography-body1 tx9" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>
							<div className="sep10"></div>

							<div className="lab-date">
								<Typography>{this.state.data.date}</Typography>
							</div>

							<div className="sep10"></div>
				  			<div className="btns">
								{this.state.data.marker && <Button className="xbtn" onClick={() => window.open(this.state.data.marker)} variant="contained" color="secondary">Mappa</Button>}
								{this.state.data.attachment && <Button onClick={() => window.open(this.state.data.attachment)} variant="contained" color="secondary" className="xbtn">Locandina</Button>}
							</div>
						</div>}
					</>
				</div>
			</>
		);
	}

}

export default withRouter(Event);
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider} from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter, Route } from 'react-router-dom';
import './Style.css';
import BottomTabs from './BottomTabs';
import Home from './Home';
import ChiSiamo from './ChiSiamo';
import Eventi from './Eventi';
import Evento from './Evento';
import Laboratori from './Laboratori';
import Laboratorio from './Laboratorio';


const theme = createMuiTheme({
	typography: {
		fontFamily: [
			'"Titillium Web"',
			'-apple-system',
			'sans-serif',
		].join(','),
	},

	palette: {
		primary: {
			main: '#179dd8',
			contrastText: '#fff',
		},
		secondary: {
			main: '#e00c7a',
			contrastText: '#fff',
		},
	},
});



class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			nav: null,
			questions: null,
		}

		this.setNav = this.setNav.bind(this);
	}


	setNav(val) {
		this.setState({nav: val})
	}


	render() {
		return (
			<ThemeProvider theme={theme}>
				<div className="app-container">
					<BrowserRouter basename="/">
						<Route exact path="/">
							<AppBar className="app-bar" position="sticky"><Toolbar><Typography className="app-bar-title">ViviAMO Parco San Laise</Typography></Toolbar></AppBar>
							<Home setNav={this.setNav} />
						</Route>
						<Route exact path="/chi-siamo">
							<AppBar className="app-bar" position="sticky"><Toolbar><Typography className="app-bar-title">Chi Siamo</Typography></Toolbar></AppBar>
							<ChiSiamo setNav={this.setNav}  />
						</Route>
						<Route exact path="/eventi">
							<AppBar className="app-bar" position="sticky"><Toolbar><Typography className="app-bar-title">Eventi</Typography></Toolbar></AppBar>
							<Eventi setNav={this.setNav}  />
						</Route>
						<Route path="/evento/:permalink">
							<Evento setNav={this.setNav}  />
						</Route>
						<Route exact path="/laboratori">
							<Laboratori setNav={this.setNav}  />
						</Route>
						<Route path="/lab/:permalink">
							<Laboratorio setNav={this.setNav}  />
						</Route>
						<BottomTabs nav={this.state.nav} setNav={this.setNav} />
					</BrowserRouter>
				</div>
			</ThemeProvider>
		);
	}
}

export default App;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import logo from './imgs/logo1.jpg';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import LogoHome from './imgs/logo-app.png';


const HomeBox = props => {
	return <Button onClick={props.onClick}>
		<div className="home-box">
			<Typography component="div" className="tx1">
				{props.title}
			</Typography>
			<div className="home-sep"></div>
			<Typography component="div" className="tx5">
				{props.text}
			</Typography>
		</div>
	</Button>
}

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.setNav = this.setNav.bind(this);
	}

	setNav(val) {
		switch (val) {
			case 0:
				this.props.history.push('/chi-siamo');
				break;
			case 1:
				this.props.history.push('/eventi');
				break;
			case 2:
				this.props.history.push('/laboratori');
				break;
		}
	}

	render() {
		return (
			<div className="app-content">
				<div className="home-container">
					<Button onClick={() => this.setNav(0)}>
						<div className="home-box">
							<Typography component="div" className="tx1">
								CHI SIAMO
							</Typography>
							<div className="home-sep"></div>
							<img src={LogoHome} alt="" className="logo-home" />
						</div>
					</Button>
					<HomeBox title="EVENTI" text="" onClick={() => this.setNav(1)} />
	  				<HomeBox title="LABORATORI" text="" onClick={() => this.setNav(2)} />
				</div>
			</div>
		);
	}
}


export default withRouter(Home);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { ajaxReq } from './Helper';
import Loader from './imgs/progress.svg';


const Lab = (props) => {
	return <Button className="btn-lab" onClick={props.onClick}>
		<div className="lab">
			<Typography className="tx8">{props.data.title}</Typography>
			<div className="lab-info">
				{props.data.image && <img src={props.data.image} className="lab-img" alt="" />}
				<div className="lab-side">
					<div className="lab-date">
						<Typography className="lab-date-d">{props.data.start}</Typography>
						{/*<Typography className="lab-date-y">{props.data.year}</Typography>*/}
					</div>
					<Typography className="lab-category">{props.data.age}</Typography>
				</div>
			</div>
		</div>
	</Button>
}


class Laboratori extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tbActive: false,
			fullLab: [],
			laboratori: [],
			searchVal: '',
			showLoader: true,
		}

		this.toggleTbActive = this.toggleTbActive.bind(this);
		this.labOnClick = this.labOnClick.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.searchInput = React.createRef();
	}

	componentDidMount() {
		this.props.setNav(2);
		ajaxReq('laboratori', null, (resp) => {
			this.setState({fullLab:resp.data.activities, laboratori: resp.data.activities, showLoader: false})
		}, null, null, null);

	}

	labOnClick(id) {
		this.props.history.push('/lab/' + id);
	}

	toggleTbActive() {
		this.setState({tbActive : !this.state.tbActive}, () => {
			if (this.state.tbActive) {
				const sInput = this.searchInput.current;
				setTimeout(() => {
					sInput.focus();
				}, 100)
			} else {
				this.setState({searchVal: ''});
				this.handleSearch();
			}
		});
	}

	handleSearch(ev) {
		const sInput = this.searchInput.current;
		let newList = [];
		if (sInput && this.state.fullLab) {
			this.setState({searchVal: ev.currentTarget.value});
			const re = new RegExp(sInput.value, 'i');
			this.state.fullLab.map(itm => {
				if (itm.title.match(re)) {
					newList.push(itm)
				}
			})
		} else {
			newList = this.state.fullLab;
		}
		this.setState({laboratori: newList});
	}

	render() {
		return <>
			<AppBar className="app-bar" position="sticky">
				<Toolbar className={this.state.tbActive ? 'tb-active' : ''}>
		            <div className="app-bar-search-icon" onClick={this.toggleTbActive}><SearchIcon /></div>
					{!this.state.tbActive && <Typography className="app-bar-title2">Laboratori</Typography>}
					{this.state.tbActive && <InputBase className="tb-search" value={this.state.searchVal} placeholder="Cerca…" inputRef={this.searchInput} onChange={this.handleSearch} />}
				</Toolbar>
			</AppBar>
			<div className="app-content">
				{this.state.showLoader && <div className="loader-content"><img src={Loader} alt="" /></div>}
				{this.state.laboratori && this.state.laboratori.map(itm => <Lab key={itm.permalink} data={itm} onClick={() => this.labOnClick(itm.permalink)} />)}
			</div>
		</>
	}

}

export default withRouter(Laboratori);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ChiSiamoImg from './imgs/chi-siamo.jpg';


export default class ChiSiamo extends React.Component {

	componentDidMount() {
		this.props.setNav(0);
	}

	render() {
		return (
			<div className="app-content">
				<img src={ChiSiamoImg} className="img2" alt="" />
				<div className="main-container">
					<Typography>
La Fondazione Banco di Napoli per l'Assistenza all'Infanzia (FBNAI) è una Asp (Azienda Pubblica di Servizi alla Persona) iscritta nell'apposito registro istituito dalla Regione Campania con regolamento n ° 2/2013.
<br /><br />
Ha come mission sostenere i processi di autonomia e di benessere di minori, giovani e comunità non solo nella provincia di Napoli, ma su tutto il territorio Campano.
<br /><br />
Promuove e attua azioni di contrasto al disagio adolescenziale, lotta alla povertà educativa e linee di intervento finalizzate allo sviluppo di percorsi riguardanti il ciclo educativo infanzia 0-6 anni.
<br />


					</Typography>
				</div>
			</div>
		);
	}

}
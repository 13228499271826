import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import Loader from './imgs/progress.svg';
import { ajaxReq } from './Helper';
import { withRouter } from 'react-router-dom';
import IcoEm1 from './imgs/emoji-1.png';
import IcoEm2 from './imgs/emoji-2.png';
import IcoEm3 from './imgs/emoji-3.png';
import IcoStar from './imgs/star.png';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';


const Question = props => {
	const [icon1Active, setIcon1Active] = React.useState(false);
	const [icon2Active, setIcon2Active] = React.useState(false);
	const [icon3Active, setIcon3Active] = React.useState(false);

	const clickIcon = (ev) => {
		const vote = parseInt(ev.currentTarget.getAttribute('data-vote'));
		const qId = ev.currentTarget.parentNode.getAttribute('data-id');
		switch (vote) {
			case 1:
				if (icon1Active) {
					setIcon1Active(false);
					props.onVote(qId, '');
				} else {
					setIcon1Active(true);
					setIcon2Active(false);
					setIcon3Active(false);
					props.onVote(qId, ev.currentTarget.getAttribute('data-vote'));
				}
				break;
			case 2:
				if (icon2Active) {
					setIcon2Active(false);
					props.onVote(qId, '');
				} else {
					setIcon1Active(false);
					setIcon2Active(true);
					setIcon3Active(false);
					props.onVote(qId, ev.currentTarget.getAttribute('data-vote'));
				}
				break;
			case 3:
				if (icon3Active) {
					setIcon3Active(false);
					props.onVote(qId, '');
				} else {
					setIcon1Active(false);
					setIcon2Active(false);
					setIcon3Active(true);
					props.onVote(qId, ev.currentTarget.getAttribute('data-vote'));
				}
				break;
		}
	} 

	return <div className="question">
		{props.title && <Typography className="tx7">{props.title}</Typography>}
		<Typography>{props.text}</Typography>
		<div className="votes" data-id={props.id}>
			<img src={IcoEm1} onClick={clickIcon} data-vote="1" className={icon1Active ? 'qico active' : 'qico'} alt="" />
			<img src={IcoEm2} onClick={clickIcon} data-vote="2" className={icon2Active ? 'qico active' : 'qico'} alt="" />
			<img src={IcoEm3} onClick={clickIcon} data-vote="3" className={icon3Active ? 'qico active' : 'qico'} alt="" />
		</div>
	</div>
}



class Laboratorio extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openDrawer: false,
			votes: {},
			showLoader: true,
			data: null,
			questions: null,
			alertMsg: null,
			alertMode: '',
			canSave: false,
			sEmail: '',
			validEmail: false,
		}

		this.openDrawer = this.openDrawer.bind(this);
		this.closeDrawer = this.closeDrawer.bind(this);
		this.onVote = this.onVote.bind(this);
		this.back = this.back.bind(this);
		this.saveVotes = this.saveVotes.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
		this.sEmailValue = this.sEmailValue.bind(this);
	}

	componentDidMount() {
		this.props.setNav(2);
		ajaxReq('laboratori/' + this.props.location.pathname.split('/')[2], null, (resp) => {
			this.setState({data:resp.data.activity, showLoader: false})
		}, null, null, null);

		ajaxReq('domande', null, (resp) => {
			this.setState({questions:resp.data.questions})
		}, null, null, null);
	}

	back() {
		this.props.history.push('/laboratori');
	}

	closeSnackbar() {
		this.setState({alertMsg: null});
	}

	closeDrawer() {
		this.setState({openDrawer: false});
	}

	openDrawer() {
		/*
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		*/
		this.setState({openDrawer: true, canSave: false, validEmail: false, sEmail: ''});
	}

	onVote(id, vote) {
		const votes = this.state.votes;
		votes['domanda-id-' + id] = vote;
		this.setState({votes: votes}, () => {this.updateBtnSave()});
	}

	saveVotes() {
		if (!this.state.canSave) {
			return;
		}
		let votes = this.state.votes;
		votes.id = this.state.data.id;
		votes.email = this.state.sEmail;
		ajaxReq('questionario', votes, (resp) => {
			if (resp.result === 'ok') {
				this.setState({openDrawer: false, alertMsg: 'Grazie per il tuo voto', alertMode: 'success'});
			} else {
				this.setState({openDrawer: false, alertMsg: 'Ops!! c\'è stato un errore nel salvataggio dei dati', alertMode: 'error'});
			}
		}, null, null, null);
	}

	sEmailValue(ev) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(ev.currentTarget.value).toLowerCase())) {
			this.setState({validEmail: true}, () => {
				this.updateBtnSave();
			});
		} else {
			this.setState({validEmail: false, canSave: false});
		}

		this.setState({sEmail: ev.currentTarget.value});
	}

	updateBtnSave() {
		console.log('updateBtnSave', Object.keys(this.state.votes).length, this.state.questions.length, this.state.sEmail)
		if (this.state.validEmail && Object.keys(this.state.votes).length === this.state.questions.length && this.state.sEmail) {
			this.setState({canSave: true});
		}
	}

	render() {
		return <>
			<div className="app-content">
				<AppBar className="app-bar" position="sticky">
					<Toolbar>
			            <div className="app-bar-back" onClick={this.back}><KeyboardBackspace /></div>
						<Typography variant="h6" className="app-bar-title2">{this.state.data && this.state.data.title}</Typography>
					</Toolbar>
				</AppBar>
				{this.state.showLoader && <div className="loader-content"><img src={Loader} alt="" /></div>}


				<Snackbar
					className={this.state.alertMode + ' alertmsg'}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={Boolean(this.state.alertMsg)}
					onClose={this.closeSnackbar}
					message={this.state.alertMsg}
					action={
						<React.Fragment>
							<IconButton
							aria-label="Chiudi"
							color="inherit"
							onClick={this.closeSnackbar}
							>
							<CloseIcon />
							</IconButton>
						</React.Fragment>
					}
				/>

				{this.state.data && <>
					<Drawer anchor="bottom" open={this.state.openDrawer} onClose={this.closeDrawer}>
						<AppBar position="sticky" className="app-bar-2">
							<Toolbar>
					            <div className="app-bar-back" onClick={this.closeDrawer}><CloseIcon /></div>
								<Typography variant="h6" className="app-bar-title2">VALUTAZIONE</Typography>
							</Toolbar>
						</AppBar>
						<div className="question-container">
							<div className="main-container">
								<div className="question">
									<Typography>Inserisci l'email che hai utilizzato al momento della registrazione</Typography>
									<div className="votes">
										<input type="text" className="srv-email" name="email" onChange={this.sEmailValue} value={this.state.sEmail} />
									</div>
								</div>
								{this.state.questions && this.state.questions.map(itm => <Question key={itm.id} id={itm.id} title={itm.titolo} text={itm.domanda} onVote={this.onVote} />)}
							</div>
						</div>
			  			<div className="btns-vote bottomnav">
							<Button variant="contained" color="secondary" onClick={this.saveVotes} className={this.state.canSave ? 'xbtn' : 'xbtn save-survey-inactive'}>Salva</Button>
			  			</div>
					</Drawer>
					<img src={this.state.data.image} className="img3" alt="" />
					{this.state.data.survey && <Button onClick={this.openDrawer} className="btn-survey"><img src={IcoStar} alt="" /><div className="stxt">Valuta</div></Button>}
					<div className="main-container">
						{this.state.data.association && this.state.data.association.name && <Typography className="tx6">
			  				<b>Organizzatore</b> {this.state.data.association.name}
						</Typography>}


				        <div className="MuiTypography-body1 tx9" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>

				        <div className="lab-date">
							<Typography>
				  				{this.state.data.start} - {this.state.data.end}
							</Typography>
						</div>
						<div class="sep10"></div>

			  			<div className="btns">
							{/*this.state.data.phone && <Button className="xbtn" href={"tel:" + this.state.data.phone} variant="contained" color="secondary">Chiama</Button>*/}
							{this.state.data.marker && <Button className="xbtn" onClick={() => window.open(this.state.data.marker)} variant="contained" color="secondary">Mappa</Button>}
							{this.state.data.calendar &&<Button onClick={() => window.open(this.state.data.calendar)} variant="contained" color="secondary" className="xbtn">Calendario</Button>}
							<Button className="xbtn" href={"mailto:iscrizioni.progetti@fbnai.it?subject=Richiesta%20informazioni%20" + this.state.data.title} variant="contained" color="secondary">Contatti</Button>
			  			</div>
						<div className="sep10"></div>
					</div>
				</>}
			</div>
		</>;
	}

}


export default withRouter(Laboratorio);